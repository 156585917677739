import React from 'react';
import Spinner from '../common/Spinners/Spinner';

interface UserPlaceholderProps {
  theme: string;
  text: string;
}

// function UserPlaceholder(props: object) {
function UserPlaceholder({ theme, text }: UserPlaceholderProps) {
  return (
    <div className="d-flex mt-3">
      <div
        className={`bg_${theme}_card summary_section_shadow w-50`}
        style={{ paddingBottom: 90 }}
      >
        <Spinner theme={theme} animationName="blocks" text={text} />
      </div>
      <div
        className={`insights_weekly_container_${theme} w-50`}
        style={{ paddingBottom: 90 }}
      >
        <Spinner theme={theme} animationName="spin" text={text} />
      </div>
    </div>
  );
}

export default UserPlaceholder;
