import React from 'react';
import { Button, makeStyles } from '@fluentui/react-components';

interface Buttonrops {
  title: string,
  onClick(): void,
  disabledFocusable?: boolean
}

const useLaunchButtonStyles = makeStyles({
  primaryButton: {
    whiteSpace: 'nowrap',
    width: 'fit-content',
    height: '48px',
    textAlign: 'center',
    backgroundColor: '#1673A3',
    paddingTop: '10px',
    paddingRight: '20px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    ':hover': {
      backgroundColor: '#0E567B',
    },
    ':hover:active': {
      backgroundColor: '#0E567B',
    },
  },
  disabled: {
    whiteSpace: 'nowrap',
    width: 'fit-content',
    height: '48px',
    textAlign: 'center',
    paddingTop: '10px',
    paddingRight: '20px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    backgroundColor: '#B3B3B3',
    color: '#FFFF',
    ':hover': {
      backgroundColor: '#B3B3B3',
      color: '#FFFF',
    },
    ':hover:active': {
      backgroundColor: '#B3B3B3',
      color: '#FFFF',
    },
  },
});

function InsightsButton({ onClick, title, disabledFocusable = false }: Buttonrops) {
  const styles = useLaunchButtonStyles();

  return (
    <Button
      className={!disabledFocusable ? styles.primaryButton : styles.disabled}
      disabledFocusable={disabledFocusable}
      appearance="primary"
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

export default InsightsButton;
