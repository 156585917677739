/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface TeamsButtonProps {
  btnText: string;
  onClick: any;
  theme: string;
  disabled?: boolean;
}

/**
 * A simple header and paragraph text container.
 */
function TeamsButton({
  btnText,
  onClick,
  theme,
  disabled = false,
}: TeamsButtonProps) {
  return (
    <button
      type="button"
      className={`teamsButton teamsButton${theme}`}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      disabled={disabled}
      onClick={onClick}
    >
      {btnText}
    </button>
  );
}

export default TeamsButton;
