import React from 'react';
import Spinner from '../common/Spinners/Spinner';

interface SidePanelPlaceholderProps {
  theme: string;
  text: string;
}

function SidePanelPlaceholder({ theme, text }: SidePanelPlaceholderProps) {
  return (
    <div>
      <Spinner theme={theme} animationName="spin" text={text} />
    </div>
  );
}

export default SidePanelPlaceholder;
