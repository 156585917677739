import React from 'react';
import Spinner from '../common/Spinners/Spinner';

interface MeetingPlaceholder {
  theme: string;
  text: string;
}

function MeetingPlaceholder({ theme, text }: MeetingPlaceholder) {
  return (
    <div
      className={`bg_${theme}_card summary_section_shadow mt-3`}
      style={{ paddingBottom: 90 }}
    >
      <Spinner theme={theme} animationName="wavy" text={text} />
    </div>
  );
}

export default MeetingPlaceholder;
