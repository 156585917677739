/* eslint-disable no-nested-ternary */
import React from 'react';
import { Label, makeStyles } from '@fluentui/react-components';
import { tokens } from '@fluentui/react-theme';

interface EnergyRowProps {
  theme: string;
  energyName: string;
  energyStyle: string;
  index: number;
  aboveLineIndex: number;
  fullscreen?: boolean;
}

const useStyles = makeStyles({
  labelWhiteStyle: { color: tokens.colorNeutralBackground1 },
  labelBlackStyle: { color: tokens.colorNeutralForeground1 },
});

/**
 * Displays the energy as a row - name and appropriate style.
 */
function EnergyRow({
  theme,
  energyName,
  energyStyle,
  index,
  aboveLineIndex,
  fullscreen = false,
}: EnergyRowProps) {
  const classes = useStyles();
  return (
    // eslint-disable-next-line no-nested-ternary
    <div
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 3,
        paddingBottom: 5,
      }}
      className={`${
        aboveLineIndex - 1 === index ? '' : `border_${theme}_row`
      } border_${theme} colour_energy ${
        fullscreen ? 'col-12' : ''
      } ${energyStyle}_${theme} ${
        index === 0
          ? 'colour_energy_rounded_top'
          : index === 3
            ? 'colour_energy_rounded_bottom'
            : ''
      }`}
    >
      {(energyStyle === 'sunshineYellowEnergy' || theme === 'dark')
      && theme !== 'contrast' ? (
        <Label className={classes.labelBlackStyle} weight="semibold">
          {energyName}
        </Label>
        ) : (
          <Label className={classes.labelWhiteStyle} weight="semibold">
            {energyName}
          </Label>
        )}
    </div>
  );
}
export default EnergyRow;
