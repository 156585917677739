import React from 'react';
import { useTranslation } from 'react-i18next';

interface NoEnergySummary {
  theme: string;
}

interface NoEnergySummary {
  theme: string;
}

/**
 * Placeholder for when no colour energy summary avaliable or user has not consented to show
 * their colour energies.
 */
function NoEnergySummary({ theme }: NoEnergySummary) {
  const { t } = useTranslation();
  return (
    <div className="flex-column">
      <div className="colour_energy col-12" style={{ padding: 0 }}>
        <span className={`avatarParagraph_${theme}`}>
          {t('ms-teams-person-no-colour-energies')}
        </span>
      </div>
    </div>
  );
}

export default NoEnergySummary;
