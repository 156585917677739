/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDoDontIconColour, getTextLabelColour } from '../../../util/theme';
import { StatementValues } from '../../../global/types';

interface CommsDoDontProps {
  id?: string;
  theme: string;
  dos: StatementValues[];
  donts: StatementValues[];
  compareProfile?: boolean;
}
function List(items: StatementValues[], theme: string) {
  return (
    <ul className="col-12 pb-0">
      {items.map((item) => (
        <li key={item.text} className={`personal_profile_subtext avatarTitle_${theme}`}>
          {item.text}
        </li>
      ))}
    </ul>
  );
}

function CommsDoDont({
  id,
  theme,
  dos = [],
  donts = [],
  compareProfile = false,
}: CommsDoDontProps) {
  const { t } = useTranslation();
  const [all, setAll] = useState(false);

  const handleShowAll = () => setAll(!all);

  return (
    <div className="d-flex flex-column" id={id} style={{ marginTop: 8 }}>
      <div
        className={`d-flex ${
          compareProfile ? 'flex-column' : 'flex-md-row flex-column'
        } `}
      >
        <div className="strengths_weakness_column">
          <h3
            className={`section_title do_box_border_${theme} api_content_heading_default`}
          >
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 6 }}
            >
              <g id="Icons / Regular / Check 1">
                <path
                  id="Vector"
                  d="M9.33333 0.833008L3.7437 8.81782C3.66995 8.9238 3.57208 9.01073 3.45814 9.07145C3.3442 9.13218 3.21746 9.16496 3.08837 9.16709C2.95928 9.16923 2.83153 9.14065 2.71564 9.08373C2.59976 9.0268 2.49906 8.94315 2.42185 8.83967L1 6.94412"
                  stroke={getDoDontIconColour(theme, true)}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            {t('ms-teams-personal-profile-do')}
          </h3>
          {dos.length === 0 ? (
            <ul className="col-12 pb-0">
              <li className={`personal_profile_subtext avatarTitle_${theme}`}>
                {t('ms-teams-profile-no-statements')}
              </li>
            </ul>
          ) : (
            List(all ? dos : dos.slice(0, 2), theme)
          )}
        </div>
        <div className="strengths_weakness_column">
          <h3
            className={`section_title dont_box_border_${theme} api_content_heading_default`}
          >
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 6, marginBottom: 2 }}
            >
              <g id="Icons / Regular / Close">
                <path
                  id="Vector"
                  d="M1 9L9.33333 1"
                  stroke={getDoDontIconColour(theme, false)}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M9.33333 9L1 1"
                  stroke={getDoDontIconColour(theme, false)}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            {t('ms-teams-personal-profile-dont')}
          </h3>
          {donts.length === 0 ? (
            <ul className="col-12 pb-0">
              <li className={`personal_profile_subtext avatarTitle_${theme}`}>
                {t('ms-teams-profile-no-statements')}
              </li>
            </ul>
          ) : (
            List(all ? donts : donts.slice(0, 2), theme)
          )}
        </div>
      </div>
      {(donts.length > 2 || dos.length > 2) ? (
        <button
          type="button"
          onClick={handleShowAll}
          className=" personal_dashboard_button"
          style={{ color: getTextLabelColour(theme), marginBottom: 12 }}
        >
          {all ? t('ms-teams-personal-profile-show-less') : t('ms-teams-personal-profile-show-all')}
        </button>
      ) : null}
    </div>
  );
}

export default CommsDoDont;
