/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import { dialog, app } from '@microsoft/teams-js';
import { UserContext } from '../../context/user-context';
// import MainSpinner from '../common/Spinners/MainSpinner';
import { getTextLabelColour } from '../../util/theme';
import Checkbox from '../common/Checkbox';
import { StatementEnhanced, StatementPrivacyItem } from '../../global/types';
import Error from '../common/Error/Error';
import { postUpdateUserStatementSettings, getSignedInUser } from '../../services/learnersService';
import Spinner from '../common/Spinners/Spinner';
import TeamsButton from '../common/TeamsButton';

interface RowSectionProps {
  title: string;
  updatePersonalFunc: any;
  updatePublicFunc: any;
  namePersonal: string;
  idPersonal: string;
  namePublic: string;
  idPublic: string;
  theme: string;
  checkboxValues: StatementPrivacyItem;
}

interface StatementsToggleTableProps {
  title: string;
  updateSettings: any;
  statementSectionId: string;
  statements: StatementEnhanced[];
  statementSettings: any[];
  uncheckAllPersonal: any;
  uncheckAllPublic: any;
  theme: string;
  isUncheckedAll?: any;
}

interface TableDividerProps {
  theme: string;
}

function TableDivider({ theme }: TableDividerProps) {
  return (
    <div
      className={`compare_profile_outer_border_${theme}`}
      style={{
        marginBottom: 16,
        borderWidth: 0,
        borderTopWidth: 1,
        borderStyle: 'solid',
      }}
    />
  );
}

function TableRow({
  title,
  checkboxValues,
  updatePersonalFunc,
  updatePublicFunc,
  namePersonal,
  idPersonal,
  namePublic,
  idPublic,
  theme,
}: RowSectionProps) {
  return (
    <tr style={{ height: 40 }}>
      <td style={{ width: '60%' }} className={`text_colour_${theme} pb-3`}>
        {title}
      </td>
      <td
        style={{
          width: '20%',
        }}
        className="pb-3"
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Checkbox
            theme={theme}
            label={null}
            onClick={updatePersonalFunc} // todo
            value={checkboxValues.personal}
            name={namePersonal}
            id={idPersonal}
          />
        </div>
      </td>
      <td
        style={{
          width: '20%',
        }}
        className="pb-3"
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Checkbox
            theme={theme}
            label={null}
            onClick={updatePublicFunc}
            value={checkboxValues.public}
            name={namePublic}
            id={idPublic}
          />
        </div>
      </td>
    </tr>
  );
}

function StatementsToggleTable({
  title,
  updateSettings,
  statementSectionId,
  statements,
  statementSettings,
  theme,
  uncheckAllPersonal,
  uncheckAllPublic,
  isUncheckedAll,
}: StatementsToggleTableProps) {
  const { t } = useTranslation();
  return (
    <table>
      <tr>
        <th
          className={`customise_section_title avatarTitle_${theme}`}
          style={{ width: '60%' }}
        >
          {title}
        </th>
        <th
          className={`avatarParagraph_${theme} avatarLink text-center`}
          style={{ width: '20%' }}
        >
          {t('ms-teams-customise-personal-title')}
        </th>
        <th
          className={`avatarParagraph_${theme} avatarLink text-center`}
          style={{ width: '20%' }}
        >
          {t('ms-teams-customise-public-title')}
        </th>
      </tr>
      <tr>
        <td style={{ width: '60%' }} className="pb-2" />
        <td
          style={{
            width: '20%',
          }}
          className="pb-2"
        >
          <button
            type="button"
            className="useProfileText"
            style={{
              color: getTextLabelColour(theme),
              width: '100%',
              textAlign: 'center',
            }}
            onClick={uncheckAllPersonal}
          >
            {isUncheckedAll.personal ? t('ms-teams-customise-uncheck-all') : t('ms-teams-customise-check-all')}
          </button>
        </td>
        <td style={{ width: '20%' }} className="pb-2">
          <button
            type="button"
            className="useProfileText"
            style={{
              color: getTextLabelColour(theme),
              width: '100%',
              textAlign: 'center',
            }}
            onClick={uncheckAllPublic}
          >
            {isUncheckedAll.public ? t('ms-teams-customise-uncheck-all') : t('ms-teams-customise-check-all')}
          </button>
        </td>
      </tr>
      {statements.map((statement: any) => {
        const { id, text } = statement;
        return (
          <TableRow
            title={text}
            checkboxValues={statementSettings[id]}
            updatePersonalFunc={() => {
              const prevValue = statementSettings[id].personal;
              const publicValue = statementSettings[id].public;
              updateSettings(statementSectionId, id, {
                personal: !prevValue,
                public: publicValue,
              });
            }}
            updatePublicFunc={() => {
              const prevValue = statementSettings[id].public;
              const personalValue = statementSettings[id].personal;
              updateSettings(statementSectionId, id, {
                personal: personalValue,
                public: !prevValue,
              });
            }}
            namePersonal={`${text}_Personal`}
            idPersonal={`${id}_${statementSectionId}_personal`}
            namePublic={`${text}_Public`}
            idPublic={`${id}_${statementSectionId}_public`}
            theme={theme}
          />
        );
      })}
    </table>
  );
}

function PersonalProfileModal() {
  const { t } = useTranslation();
  const context = useContext(UserContext) || { themeState: 'default' };
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [settings, setSettings] = useState<any>(null);
  const [saving, setIsSaving] = useState(false);

  const checkIsUncheckedAll = (
    statementSectionId: string,
    type: 'personal' | 'public',
    settingContext?: any,
  ) => {
    const data = settingContext || settings;
    let hasUncheckedAll = false;
    const keys = Object.keys(data?.[statementSectionId]);

    for (let keyIndex = 0; keyIndex < keys.length; keyIndex += 1) {
      const item = data?.[statementSectionId][keys[keyIndex]][type];

      if (item) {
        hasUncheckedAll = true;
        break;
      }
    }

    return hasUncheckedAll;
  };

  const [isUncheckedAll, setIsUncheckedAll] = useState({
    highlights: {
      personal: true,
      public: true,
    },
    strengths: {
      personal: true,
      public: true,
    },
    weakness: {
      personal: true,
      public: true,
    },
    communicationDos: {
      personal: true,
      public: true,
    },
    communicationDonts: {
      personal: true,
      public: true,
    },
  });

  const fetchSignedInUser = async () => {
    setIsLoadingUser(true);
    if (context && context?.updateUser) {
      await context?.updateUser();
    }
    setIsLoadingUser(false);
  };

  // Fetch the User info
  useEffect(() => {
    fetchSignedInUser();
  }, []);

  useEffect(() => {
    if (!isLoadingUser) {
      const settingContext = context.user?.userStatementSettings;
      setSettings(settingContext);

      setIsUncheckedAll({
        highlights: {
          personal: checkIsUncheckedAll('highlights', 'personal', settingContext),
          public: checkIsUncheckedAll('highlights', 'public', settingContext),
        },
        strengths: {
          personal: checkIsUncheckedAll('strengths', 'personal', settingContext),
          public: checkIsUncheckedAll('strengths', 'public', settingContext),
        },
        weakness: {
          personal: checkIsUncheckedAll('weakness', 'personal', settingContext),
          public: checkIsUncheckedAll('weakness', 'public', settingContext),
        },
        communicationDos: {
          personal: checkIsUncheckedAll('communicationDos', 'personal', settingContext),
          public: checkIsUncheckedAll('communicationDos', 'public', settingContext),
        },
        communicationDonts: {
          personal: checkIsUncheckedAll('communicationDonts', 'personal', settingContext),
          public: checkIsUncheckedAll('communicationDonts', 'public', settingContext),
        },
      });
    }
  }, [isLoadingUser]);

  const saveSettings = async () => {
    hotjar.event('statements-saved');
    setIsSaving(true);
    const teamsAppData = await app.getContext();
    let signedInUserEmail = teamsAppData.user?.userPrincipalName || context?.user?.email || '';
    const tenantId = context.user?.tenantId || teamsAppData.user?.tenant?.id;

    if (!signedInUserEmail && tenantId) {
      // backup - check graph for current user if all others fail.
      const { data } = await getSignedInUser(tenantId);
      signedInUserEmail = data?.userPrincipalName;
    }

    if (signedInUserEmail) {
      await postUpdateUserStatementSettings(signedInUserEmail, settings);
      dialog.url.submit({ result: 'saved' });
    }

    setIsSaving(false);
  };

  const fetchUserInfo = async () => {
    setIsLoadingUser(true);
    if (context && context?.updateUser) {
      await context?.updateUser();
    }
    setIsLoadingUser(false);
  };

  // Fetch the User info and obtain userStatement settings
  useEffect(() => {
    fetchUserInfo();
  }, []);

  if (isLoadingUser) {
    return (
      <div
        className={`bg_${context.themeState}_card`}
        style={{ height: '100vh' }}
      >
        <Spinner theme={context.themeState} animationName="spin" />
      </div>
    );
  }

  const updateSettings = (
    statementSectionId: string,
    id: string,
    newValues: any,
  ) => {
    const updatedSettings = settings;
    updatedSettings[statementSectionId][id] = newValues;
    setSettings(updatedSettings);

    setIsUncheckedAll({
      ...isUncheckedAll,
      [statementSectionId]: {
        personal: checkIsUncheckedAll(statementSectionId, 'personal', updatedSettings),
        public: checkIsUncheckedAll(statementSectionId, 'public', updatedSettings),
      },
    });
  };

  const uncheckAll = (
    statementSectionId: string,
    type: 'personal' | 'public',
    // value: boolean,
  ) => {
    const updatedSettings = { ...settings };
    const areAllUnchecked = checkIsUncheckedAll(statementSectionId, type);

    const option = !isUncheckedAll;

    const section = updatedSettings[statementSectionId];
    updatedSettings[statementSectionId] = Object.entries(section).reduce(
      (acc, cur) => {
        const newValues = type === 'personal'
          ? {
            personal: !areAllUnchecked,
            public: updatedSettings[statementSectionId][cur[0]].public,
          }
          : {
            personal: updatedSettings[statementSectionId][cur[0]].personal,
            public: !areAllUnchecked,
          };
        (
          document.getElementById(
            `${cur[0]}_${statementSectionId}_${type}`,
          ) as any
        ).checked = !areAllUnchecked;
        return { ...acc, [cur[0]]: newValues };
      },
      {},
    );

    setSettings(updatedSettings);
    setIsUncheckedAll({
      ...isUncheckedAll,
      [statementSectionId]: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...isUncheckedAll[statementSectionId],
        [type]: !areAllUnchecked,
      },
    });
  };

  return (
    <div className={`bg_${context.themeState}_card`}>
      {!isLoadingUser && context && context?.user && settings ? (
        context.user.error ? (
          <Error
            error={context?.user.error}
            consentScope="User.Read"
            successCallback={fetchSignedInUser}
            theme={context.themeState}
          />
        ) : (
          <>
            <div
              className={`compare_profile_outer_border_${context.themeState}`}
              style={{
                borderWidth: 0,
                borderTopWidth: 1,
                borderStyle: 'solid',
              }}
            />
            <div className="d-flex flex-column mb-5" style={{ padding: 16 }}>
              <p
                className={`avatarTitle_${context.themeState}`}
                style={{ marginBottom: 0 }}
              >
                {t('ms-teams-modal-setttings-description-1')}
                <span style={{ fontWeight: 600 }}>
                  {t('ms-teams-modal-setttings-description-2')}
                </span>
                {t('ms-teams-modal-setttings-description-3')}
              </p>
              <div
                className={`compare_profile_outer_border_${context.themeState}`}
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  borderWidth: 0,
                  borderTopWidth: 1,
                  borderStyle: 'solid',
                }}
              />
              <StatementsToggleTable
                title={t('ms-teams-customise-highlights-title')}
                statementSectionId="highlights"
                updateSettings={updateSettings}
                statements={context.user?.highlights || []}
                statementSettings={settings.highlights || []}
                theme={context.themeState}
                isUncheckedAll={isUncheckedAll.highlights}
                uncheckAllPersonal={() => uncheckAll('highlights', 'personal')}
                uncheckAllPublic={() => uncheckAll('highlights', 'public')}
              />
              <TableDivider theme={context.themeState} />
              <StatementsToggleTable
                title={t('ms-teams-personal-profile-strengths')}
                statementSectionId="strengths"
                updateSettings={updateSettings}
                statements={context.user?.strengths || []}
                statementSettings={settings.strengths || []}
                theme={context.themeState}
                isUncheckedAll={isUncheckedAll.strengths}
                uncheckAllPersonal={() => uncheckAll('strengths', 'personal')}
                uncheckAllPublic={() => uncheckAll('strengths', 'public')}
              />
              <TableDivider theme={context.themeState} />
              <StatementsToggleTable
                title={t('ms-teams-personal-profile-possible-weaknesses')}
                statementSectionId="weakness"
                updateSettings={updateSettings}
                statements={context.user?.weakness || []}
                statementSettings={settings.weakness || []}
                theme={context.themeState}
                isUncheckedAll={isUncheckedAll.weakness}
                uncheckAllPersonal={() => uncheckAll('weakness', 'personal')}
                uncheckAllPublic={() => uncheckAll('weakness', 'public')}
              />
              <TableDivider theme={context.themeState} />
              <StatementsToggleTable
                title={t('ms-teams-communicattion-do')}
                statementSectionId="communicationDos"
                updateSettings={updateSettings}
                statements={context.user?.communicationDos || []}
                statementSettings={settings.communicationDos || []}
                theme={context.themeState}
                isUncheckedAll={isUncheckedAll.communicationDos}
                uncheckAllPersonal={() => uncheckAll('communicationDos', 'personal')}
                uncheckAllPublic={() => uncheckAll('communicationDos', 'public')}
              />
              <TableDivider theme={context.themeState} />
              <StatementsToggleTable
                title={t('ms-teams-communicattion-donts')}
                statementSectionId="communicationDonts"
                updateSettings={updateSettings}
                statements={context.user?.communicationDonts || []}
                statementSettings={settings.communicationDonts || []}
                theme={context.themeState}
                isUncheckedAll={isUncheckedAll.communicationDonts}
                uncheckAllPersonal={() => uncheckAll('communicationDonts', 'personal')}
                uncheckAllPublic={() => uncheckAll('communicationDonts', 'public')}
              />
            </div>
          </>
        )
      ) : null}
      <div
        className={`col-3 mt-2 saveButton_fixed saveButton_bg_${context.themeState}`}
      >
        <div style={{ marginLeft: 26 }}>
          <TeamsButton
            theme={context.themeState}
            onClick={saveSettings}
            btnText={
              !saving
                ? t('ms-teams-settings-save-button-text')
                : t('ms-teams-saving')
            }
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalProfileModal;
