/* eslint-disable no-console */
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

import * as msal from '@azure/msal-browser';

const CLIENT_ID = `${process.env.REACT_APP_MICROSOFT_APP_ID}`;
const REDIRECT_URI = `${process.env.REACT_APP_BASE_URL}`;

const msalConfig = {
  auth: {
    // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
    clientId: CLIENT_ID,
    // Full redirect URL, in form of http://localhost:3000
    redirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
          default: console.warn(message);
        }
      },
    },
  },
};

/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit:
* https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
const loginRequest = {
  scopes: ['User.Read'],
};

/**
* Add here the scopes to request when obtaining an access token for MS Graph API.
  For more information, see:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
*/
const tokenRequest = {
  scopes: ['User.Read', 'Mail.Read'],
  // Set this to "true" to skip a cached token and go to the server to get a new token
  forceRefresh: false,
};

const requestType = {
  WRONG_PROFILE: 'Send wrong profile request',
  NEW_PROFILE: 'Send new profile request',
  MERGE_REQUEST: 'Send merge request',
  FIND_PROFILE: 'Send find profile request',
};

export {
  msalConfig,
  loginRequest,
  tokenRequest,
  requestType,
};
