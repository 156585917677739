/* eslint-disable no-shadow */
import React from 'react';
import { useTranslation } from 'react-i18next';
import EnergyRow from './EnergyRow';

interface EnergySummaryProps {
  colourEnergies: Array<string>;
  fullscreen?: boolean;
  theme: string;
  aboveLineIndex: number;
}

/**
 * Generate the order of the given persons colour energies.
 * @param {Array<String>} colourEnergies
 */
function EnergySummary({
  colourEnergies,
  fullscreen = false,
  theme,
  aboveLineIndex,
}: EnergySummaryProps) {
  const { t } = useTranslation();

  const EnergyRows = colourEnergies.map((energyCode: string, i: number) => (
    <>
      {aboveLineIndex === i ? <div className={`energy_row_above_line energy_row_above_line_${theme}`} /> : null}
      <EnergyRow
        theme={theme}
        energyName={t(`ms-teams-energy-display-name-${energyCode}`)}
        energyStyle={energyCode}
        index={i}
        aboveLineIndex={aboveLineIndex}
        fullscreen={fullscreen}
      />
    </>
  ));

  return <div className="flex-column">{EnergyRows}</div>;
}

export default EnergySummary;
