/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColourEnergyValues, colourEnergy } from '../../../global/types';

interface EnergySplitProps {
  theme: string;
  colourEnergyValues: ColourEnergyValues;
  fullProfile: boolean;
  lng?: string;
}

function EnergySplit({
  theme,
  colourEnergyValues,
  fullProfile,
  lng,
}: EnergySplitProps) {
  const { t } = lng ? useTranslation('translation', { lng }) : useTranslation();

  const initials = {
    coolBlueEnergy: t('ms-teams-energy-block-initial-blue'),
    fieryRedEnergy: t('ms-teams-energy-block-initial-red'),
    earthGreenEnergy: t('ms-teams-energy-block-initial-green'),
    sunshineYellowEnergy: t('ms-teams-energy-block-initial-yellow'),
  };

  const calculateColourWidths = (values: ColourEnergyValues) => {
    const total = Object.values(values).reduce((a, b) => a + b);

    const entries = Object.entries(values).sort(
      ([, a]: [string, any], [, b]: [string, any]) => b - a,
    ); // e.g. output = [["yellow", 90], ["red", 67], ...].

    if (fullProfile) {
      const output = entries.map((energy) => {
        const colour = energy[0];
        const value = energy[1];
        const width = Math.round((+value / total) * 100);
        return { colour, value, width };
      });

      return output.map(({ colour, value, width }, index) => (
        <div
          key={colour}
          style={{ minWidth: `${width}%` }}
          className="energyBlockOuter"
        >
          <div
            style={{ minWidth: '100%' }}
            className={`${colour}_${theme} energyBlock ${
              index === 0
                ? 'exploreBorderLeft'
                : index === 3
                  ? 'exploreBorderRight'
                  : ''
            }`}
          >
            <span className="energyBlockText">
              {initials[colour as colourEnergy]}
            </span>
          </div>
          <span
            className={`percentageLabel percentageLabel_${theme}`}
            style={{ marginBottom: 10 }}
          >
            {value}
            {t('ms-teams-percentage')}
          </span>
        </div>
      ));
    }
    return entries.map((energy, index) => {
      const colour = energy[0];
      return (
        <div
          key={colour}
          style={{ minWidth: '25%' }}
          className={`energyBlockOuter ${
            index === 0
              ? 'exploreBorderLeft'
              : index === 3
                ? 'exploreBorderRight'
                : ''
          } ${colour}_${theme} energyBlock
          ${index < 3 ? 'energyBlockBorderRight' : ''}`}
        >
          <span className="energyBlockText">
            {initials[colour as colourEnergy]}
          </span>
        </div>
      );
    });
  };

  return (
    <div
      className="colourEnergySplitContainer"
      style={!fullProfile ? { marginTop: 22, marginBottom: 26 } : {}}
    >
      {calculateColourWidths(colourEnergyValues)}
    </div>
  );
}

export default EnergySplit;
