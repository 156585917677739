import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, makeStyles } from '@fluentui/react-components';
import { tokens } from '@fluentui/react-theme';
import { getErrorTextColour } from '../util/theme';

interface ErrorProps {
  theme?: string;
}

const useStyles = makeStyles({
  titleStyle: { marginBottom: tokens.spacingVerticalXS },
});

function Error({
  theme = 'default',
}: ErrorProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column p-5">
      <Label
        className={classes.titleStyle}
        style={{ color: getErrorTextColour(theme) }}
        size="large"
      >
        {' '}
        {t('ms-teams-error-title')}
      </Label>
      <Label style={{ color: getErrorTextColour(theme) }}>
        {t('ms-teams-error-initialising-sdk')}
      </Label>

    </div>
  );
}

export default Error;
