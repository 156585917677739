/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hotjar } from 'react-hotjar';
// eslint-disable-next-line import/no-extraneous-dependencies
import domtoImage from 'dom-to-image';
import { Label } from '@fluentui/react-components';
import { UserContext } from '../context/user-context';
import Checkbox from './common/Checkbox';
import DonutChart from './common/DonutChart';
import {
  getSignedInUserPhoto,
  updateUserPhoto,
} from '../services/learnersService';
import Error from './common/Error/Error';
import SmallSpinner from './common/Spinners/SmallSpinner';
import {
  getTextLabelColour,
  getErrorColour,
  getErrorTextColour,
} from '../util/theme';
import TeamsButton from './common/TeamsButton';
import useTabSize from '../util/useTabSize';
import Spinner from './common/Spinners/Spinner';

function Avatar() {
  const tabSize = useTabSize();
  const { t } = useTranslation();
  const context = useContext(UserContext) || { themeState: 'default' };
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isGettingUserProfilePhoto, setIsGettingUserProfilePhoto] = useState(false);
  const [isUserProfilePhotoError, setUserProfilePhotoError] = useState(false);
  const [updateProfilePhotoError, setUpdateProfilePhotoError] = useState(false);
  const [updateStatusMessage, setUpdateStatusMessage] = useState('');
  const [blkWht, setBlkWht] = useState(false);
  const [useProfilePic, setUseProfilePic] = useState(false);

  const [photo, setPhoto] = useState<string>('');
  const [uploadError, setUploadError] = useState<any>(null);
  const [updateError, setUpdateError] = useState<any>(null);
  const [exporting, setExporting] = useState(false);
  const [uploading, setUploading] = useState(false);

  const fileUploadFunction = (file: File) => {
    if (typeof file === 'string') {
      setUploadError(file);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imgBase64 = reader.result;
        setPhoto(imgBase64 as string);
      };
      reader.onerror = (error) => {
        setPhoto('');
        setUploadError(error);
      };
    }
  };

  // Fetch the User Photo if toggled
  useEffect(() => {
    if (useProfilePic) {
      setIsGettingUserProfilePhoto(true);
      const fetchUSerPhoto = async () => {
        const photoResult = await getSignedInUserPhoto();
        setIsGettingUserProfilePhoto(false);
        setUserProfilePhotoError(false);
        if (photoResult.status === 200) {
          setUseProfilePic(false);
          setPhoto(photoResult.data as string);
        } else {
          setUserProfilePhotoError(true);
          setUseProfilePic(false);
        }
      };
      fetchUSerPhoto();
    }
  }, [useProfilePic, setUseProfilePic]);

  const fetchSignedInUser = async () => {
    setIsLoadingUser(true);
    if (context && context?.updateUser) {
      await context?.updateUser();
    }
    setIsLoadingUser(false);
  };

  // Fetch the User info
  useEffect(() => {
    hotjar.stateChange('/avatar');
    fetchSignedInUser();
  }, []);

  const [error, setError] = useState<any>(null);

  async function captureAndDownload() {
    hotjar.event('avatar-exported');
    setExporting(true);
    setUpdateStatusMessage('');
    const elementToCapture = document.getElementById('avatarImage');

    if (elementToCapture) {
      return domtoImage.toJpeg(elementToCapture).then((dataUrl: string) => {
        const link = document.createElement('a');
        link.download = 'Insights_Profile_Avatar.png';
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExporting(false);
      });
    }

    setUploadError(t('ms-teams-upload-error'));
    setExporting(false);
    return false;
  }

  const uploadPhoto = async () => {
    try {
      hotjar.event('avatar-set-microsoft-account');
      setUploading(true);
      setUpdateStatusMessage('');
      const elementToCapture = document.getElementById('avatarImage');

      if (elementToCapture) {
        const canvas = await domtoImage
          .toJpeg(elementToCapture)
          .then((dataUrl: string) => dataUrl);

        const result = await updateUserPhoto(canvas);

        setUploading(false);

        if (result.status === 200) {
          setUpdateStatusMessage(
            t('ms-teams-avatar-uploaded-successful') || '',
          );
          return result.data;
        }

        if (result.status !== 200) {
          setUpdateProfilePhotoError(true);
          setUpdateError(result.status);
          setUpdateStatusMessage(t('ms-teams-avatar-uploaded-failure') || '');
          return result.data;
        }
      }
      setUploading(false);
      setUpdateError(t('ms-teams-upload-error'));
    } catch (e) {
      setUploading(false);
      setUpdateProfilePhotoError(true);
      setUpdateError(e);
    }
  };

  if (isLoadingUser) {
    return <Spinner theme={context.themeState} animationName="spin" />;
  }

  if (!isLoadingUser && !context.user?.colourEnergiesConfirmedAt) {
    return (
      <div
        className={`bg_${context.themeState}_main`}
        style={{ height: '100vh', minHeight: '100%' }}
      >
        <div className="d-flex flex-column p-3 pt-5 text-center">
          <Label
            className="pb-2"
            style={{ color: getErrorTextColour(context.themeState) }}
            size="large"
          >
            {' '}
            {t('ms-teams-avatar-no-colours-title')}
          </Label>
          <Label style={{ color: getErrorTextColour(context.themeState) }}>
            {t('ms-teams-avatar-no-colours-description')}
          </Label>
          <Label
            className="mt-2"
            style={{ color: getErrorTextColour(context.themeState) }}
          >
            {t('ms-teams-avatar-no-colours-description2')}
          </Label>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`bg_${context.themeState}_main avatar_container`}
      style={{ overflowY: 'auto' }}
    >
      {error ? (
        <Error
          error={error}
          consentScope="User.Read User.ReadWrite"
          successCallback={() => {
            captureAndDownload();
            setError(null);
          }}
          theme={context.themeState}
        />
      ) : !isLoadingUser && context && context.user ? (
        <>
          <DonutChart
            btnText={t('ms-teams-avatar-add-photo')}
            src={photo}
            values={context.user?.consciousColourEnergyValues}
            greyscale={blkWht}
            fileUploadFunction={fileUploadFunction}
            theme={context.themeState}
            tabWidth={tabSize.tabWidth}
          />
          {uploadError ? (
            <p className={`pt-3 error_text_${context.themeState}`}>
              {uploadError}
            </p>
          ) : null}
          {photo ? (
            <button
              type="button"
              className="useProfileText"
              style={{
                marginLeft: 3,
                marginTop: 16,
                marginBottom: 12,
                color: getErrorColour(context.themeState),
              }}
              onClick={() => {
                setPhoto('');
                setUseProfilePic(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M15.6045 5.20508L5.705 15.1046"
                  stroke={getErrorColour(context.themeState)}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M15.2952 15.1045L5.39567 5.205"
                  stroke={getErrorColour(context.themeState)}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
              Remove photo
            </button>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isGettingUserProfilePhoto ? (
                <div className="p-3 pt-4">
                  <SmallSpinner theme={context.themeState} />
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center">
                  <button
                    type="button"
                    className="useProfileText"
                    style={{
                      marginLeft: 3,
                      marginTop: 16,
                      marginBottom: 12,
                      color: getTextLabelColour(context.themeState),
                    }}
                    onClick={() => setUseProfilePic(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      style={{
                        color: getTextLabelColour(context.themeState),
                        marginRight: 2,
                      }}
                      className="bi bi-plus-md"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      />
                    </svg>
                    {t('ms-teams-avatar-add-my-current-teams-profile')}
                  </button>

                  {isUserProfilePhotoError ? (
                    <p className={`error_text_${context.themeState}`}>
                      {t('ms-teams-avatar-get-user-profile-photo-error')}
                    </p>
                  ) : null}
                </div>
              )}
            </div>
          )}
          <Checkbox
            theme={context.themeState}
            label={t('ms-teams-avatar-make-photo-black-and-white')}
            value={blkWht}
            onClick={() => setBlkWht(!blkWht)}
            name="blkWht"
            id="bw"
          />
          {error ? (
            <Error
              error={updateError}
              consentScope="User.ReadWrite"
              successCallback={() => {
                uploadPhoto();
                setUpdateError(null);
              }}
              theme={context.themeState}
            />
          ) : (
            <div className="justify-content-center align-items-center d-flex flex-column">
              <div
                className="pt-2 pb-2 d-flex justify-content-center"
                style={{
                  width: 'auto',
                  marginBottom: 8,
                  marginTop: 8,
                }}
              >
                <div
                  style={{
                    width: 'auto',
                    display: 'flex',
                    paddingRight: '8px',
                  }}
                >
                  <TeamsButton
                    theme={context.themeState}
                    onClick={uploadPhoto}
                    btnText={
                      !uploading
                        ? t('ms-teams-avatar-upload-photo')
                        : t('ms-teams-uploading')
                    }
                    disabled={!photo}
                  />
                </div>
                {tabSize.tabWidth >= 600 ? (
                  <button
                    type="button"
                    disabled={!photo}
                    className={`teamsButton teamsButtonSecondary${context.themeState}`}
                    style={{
                      width: 'auto',
                      cursor: !photo ? 'not-allowed' : 'pointer',
                    }}
                    onClick={captureAndDownload}
                  >
                    {!exporting
                      ? t('ms-teams-avatar-export-photo')
                      : t('ms-teams-exporting')}
                  </button>
                ) : null}
              </div>
              {updateStatusMessage ? (
                <p
                  className={
                    updateError
                      ? `avatar-upload-error-message avatar_error_message_${context.themeState} avatar-export-message`
                      : `avatarParagraph  avatarParagraph_${context.themeState}`
                  }
                >
                  {updateStatusMessage}
                </p>
              ) : null}
              {updateError ? (
                <p className={`pt-3 error_text_${context.themeState}`}>
                  {updateProfilePhotoError}
                </p>
              ) : null}
              <div
                className={`avatar-export-message-container avatar-export-message-container_${context.themeState}`}
              >
                <p
                  className={`avatar-export-message avatar-export-message_${context.themeState}`}
                >
                  {t('ms-teams-avatar-upload-message')}
                </p>
              </div>
            </div>
          )}
          <h3
            className={`avatarTitle avatarTitle_${context.themeState} text-center`}
          >
            {t('ms-teams-avatar-heading')}
          </h3>
          <div className="paragraphContainer" style={{ maxWidth: 450 }}>
            {' '}
            <p
              className={`avatarParagraph avatarParagraph_${context.themeState}`}
            >
              {t('ms-teams-avatar-para-1')}
            </p>
            <p
              className={`avatarParagraph avatarParagraph_${context.themeState}`}
            >
              {t('ms-teams-avatar-para-2a')}
              {' '}
              <span style={{ fontWeight: 'bold' }}>
                {t('ms-teams-avatar-para-2b')}
              </span>
              {' '}
              {t('ms-teams-avatar-para-2c')}
            </p>
          </div>
          <a
            href="https://support.microsoft.com/en-us/windows/change-your-account-picture-3645cdc7-acab-49d2-76a9-4ce7e5e10939"
            target="_blank"
            rel="noreferrer"
            className={`avatarLink avatarLink_${context.themeState}`}
            style={{
              marginTop: 8,
              marginBottom: 24,
            }}
          >
            {t('ms-teams-avatar-how-to-set-teams-avatar')}
          </a>
        </>
      ) : null}
    </div>
  );
}

export default Avatar;
