/* eslint-disable array-callback-return */

interface AllIdsInterface {
  ids: string[];
}

export interface Normalise {
  allIds: AllIdsInterface[],
  byId: {
    [key: string]: {
      [key: string]: unknown;
    };
  };
}

export default (arr = [], keyField = 'id'): Normalise => {
  const byId = {};
  const allIds: AllIdsInterface[] = [];

  arr.map((item) => {
    byId[item[keyField]] = item;
    allIds.push(item[keyField]);
  });

  return { byId, allIds };
};
