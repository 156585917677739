import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { app } from '@microsoft/teams-js';
import Dasboard from './pages/Dashboard';
import Error from './pages/Error';
import InsightsFlow from './components/Insights/InsightsFlow';
import ChatTab from './components/ChatTab';
import EnergyTabConfig from './components/EnergyTabConfig';
import ClosePopUp from './components/ClosePopup';
import OpenPopup from './components/OpenPopup';
import UserContextProvider from './context/user-context';
import './util/i18n';
import Settings from './components/Settings';
import Avatar from './components/Avatar';
import CompareProfiles from './components/CompareProfiles';
import PersonalProfileModal from './components/PersonalInfoModal';
import Spinner from './components/common/Spinners/Spinner';

function App() {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        await app.initialize();
        setIsLoading(false);
        setIsInitialized(true);
      } catch (e) {
        setIsLoading(false);
        app.notifyFailure({
          reason: app.FailedReason.Other,
          message: 'App initialization failed',
        });
      }
    })();
  }, [setIsInitialized]);

  if (isLoading) {
    return <Spinner theme="default" animationName="spin" />;
  }

  if (isInitialized) {
    return (
      <UserContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dasboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/insights" element={<InsightsFlow />} />
            <Route path="/chat" element={<ChatTab />} />
            <Route path="/tab" element={<EnergyTabConfig />} />
            <Route path="/compare-profiles" element={<CompareProfiles />} />
            <Route
              path="/customise-statements"
              element={<PersonalProfileModal />}
            />
            <Route path="/avatar" element={<Avatar />} />
            <Route path="/auth-end" element={<ClosePopUp />} />
            <Route path="/auth-start" element={<OpenPopup />} />
          </Routes>
        </BrowserRouter>
      </UserContextProvider>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
