/**
 * Simple function to tweak the app styles.
 */
export const changeTheme = (
  userTheme: string | undefined,
  lightTheme: string,
  darkTheme: string,
  contrastTheme: string,
) => {
  // if (!userTheme) {
  //   return lightTheme;
  // }
  switch (userTheme) {
    case 'dark':
      return darkTheme;
    case 'contrast':
      return contrastTheme;
    default:
      return lightTheme; // default & classic
  }
};

export const getBackgroundColour = (theme: string) => {
  switch (theme) {
    case 'dark':
      return '#1F1F1F';
    case 'contrast':
      return '#000000';
    default: // default & classic theme
      return '#f3f3f3';
  }
};

export const getSelectBackgroundColour = (theme: string) => {
  switch (theme) {
    case 'dark':
      return '#292929';
    case 'contrast':
      return '#000000';
    default: // default & classic theme
      return '#f9f9f9';
  }
};

export const getCommonSelectBorderColour = (theme: string) => {
  switch (theme) {
    case 'contrast':
      return '#ffffff';
    case 'dark':
      return '#292929';
    default:
      return '#f9f9f9';// default & classic
  }
};

/**
 * Used in placeholders to style the foreground and background colours.
 */
export const backForegroundColours = (theme: string) => {
  switch (theme) {
    case 'dark':
      return {
        backgroundColor: '#828282',
        foregroundColor: '#1f1f1f',
      };
    case 'contrast':
      return {
        backgroundColor: '#ffff54',
        foregroundColor: '#000000',
      };
    default: // default theme
      return {
        backgroundColor: '#c5c5c5', // the animation colour over the individual items.
        foregroundColor: '#f5f5f5', // the background for the placeholder individual items.
      };
  }
};

export const chartColours = (theme: string) => {
  switch (theme) {
    case 'dark':
      return [
        '#0099FF', // blue
        '#FF4C49', // red
        '#FFC43A', // yellow
        '#5EB408', // green
      ];
    case 'contrast':
      return [
        '#6EE8FC', // blue
        '#6EE8FC', // red
        '#6EE8FC', // yellow
        '#6EE8FC', // green
      ];
    default:
      return [// default & classic theme
        '#0071BB', // blue
        '#DB2F41', // red
        '#FFD74B', // yellow
        '#3B854B', // green
      ];
  }
};

export const chartLabelColour = (theme: string) => {
  switch (theme) {
    case 'dark':
      return '#FFFFFF';
    case 'contrast':
      return '#FFFFFF';
    default:
      return '#242424';// default & classic theme
  }
};

export const chartLabelYAxisTickColour = (theme: string) => {
  switch (theme) {
    case 'dark':
      return '#CCCCCC';
    case 'contrast':
      return '#FFFFFF';
    default:// default & classic theme
      return '#616161';
  }
};

export const getTextColour = (theme: string) => chartLabelColour(theme);

export const chartGridColour = (theme: string) => {
  switch (theme) {
    case 'dark':
      return '#4A4A4A';
    case 'contrast':
      return '#FFFFFF';
    default:// default & classic theme
      return '#E6E6E6';
  }
};

export const getTextLabelColour = (theme: string) => {
  switch (theme) {
    case 'dark':
      return '#7f85f5';
    case 'contrast':
      return '#FFFF54';
    default:// default & classic theme
      return '#5c5fc0';
  }
};

export const getDoDontIconColour = (theme: string, dos: boolean) => {
  switch (theme) {
    case 'dark':
      return dos ? '#1bac83' : '#ff1a44';
    case 'contrast':
      return '#6ee8fc';
    default:// default & classic theme
      return dos ? '#0d6c51' : '#93011b';
  }
};

export const getSelectHoverBorderColour = (theme: string) => {
  switch (theme) {
    case 'contrast':
      return '#ffffff';
    case 'dark':
      return '#4a4a4a';
    default:// default & classic theme
      return '#4a4a4a';
  }
};

export const getSelectBorderColour = (theme: string) => {
  switch (theme) {
    case 'contrast':
      return '#E5E5E5';
    case 'dark':
      return '#292929';
    default:// default & classic theme
      return '#292929';
  }
};

export const getErrorColour = (theme: string) => {
  switch (theme) {
    case 'contrast':
      return '#ef6950';
    case 'dark':
      return '#ef6950';
    default:// default & classic theme
      return '#a4262c';
  }
};

export const getErrorTextColour = (theme: string) => {
  switch (theme) {
    case 'contrast':
      return '#ffffff';
    case 'dark':
      return '#ffffff';
    default:// default & classic theme
      return '#242424';
  }
};
