import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColourEnergyValues } from '../../global/types';
import DashboardBox from '../common/DashboardBox/DashboardBox';

interface CommunicationCardProps {
  colourEnergyValues: ColourEnergyValues;
  noColourEnergyText: string;
  theme: string;
  colourEnergyUserCount: number;
  totalUsers: number;
}

/**
 * A simple info card that shows some communication tips
 * for the specified colour energy.
 * */
function CommunicationCard({
  colourEnergyValues,
  noColourEnergyText,
  theme,
  colourEnergyUserCount,
  totalUsers,
}: CommunicationCardProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`comms_do_dont_${theme}_card border_${theme} p-4 d-flex flex-column my-1 my-md-3 col-12 col-md-6 col-xl-6 me-md-2 ms-0 mt-3 mt-md-0`}
      style={{ flex: 1, maxHeight: 450 }}
    >
      <h3 className={`text_colour_${theme} section_title`}>
        {t('ms-teams-communication-card-title')}
      </h3>
      <p className={`text_colour_${theme} default_sub_title mb-3`}>
        {t('ms-teams-communication-card-based-on')}
        <span style={{ fontWeight: 700 }}>
          {` ${colourEnergyUserCount} ${t(
            'ms-teams-communication-card-out-of',
          )} ${totalUsers} `}
        </span>
        {t('ms-teams-communication-card-people')}
      </p>
      {colourEnergyValues ? (
        <DashboardBox
          colourEnergyValues={colourEnergyValues}
          theme={theme}
        />
      ) : (
        <p className="noCommunicationCardColours">{noColourEnergyText}</p>
      )}
    </div>
  );
}

export default CommunicationCard;
