import React from 'react';

interface TextInputProps {
  value: string;
  readOnly: boolean;
}

/**
 * A basic text input.
 */
function TextInput({ value = '', readOnly = false }: TextInputProps) {
  return (
    <input
      className="form-control bg-light"
      readOnly={readOnly}
      style={{ height: '50px', border: 'none', backgroundColor: '#F4F6F7' }}
      type="text"
      defaultValue={value}
    />
  );
}

export default TextInput;
