import React from 'react';

interface InsightsForTheWeekTile {
  title: string;
  description: string;
  theme: string;
  isHighlighted: boolean;
}

function InsightsForTheWeekTile({
  title, description, theme, isHighlighted,
}: InsightsForTheWeekTile) {
  return (
    <div className="">
      <div className={`insights_week_tile_line insights_week_tile_line_${theme} ${isHighlighted ? `insights_week_tile_line_highlighted_${theme}` : ''}`} />
      <h6 className={`insights_week_tile_title insights_week_tile_title_${theme} ${isHighlighted ? `insights_week_tile_title_highlighted_${theme}` : ''}`} style={{ display: 'inline-block', paddingLeft: '6px' }}>
        {title}
      </h6>
      <p className={`insights_week_tile_desc insights_week_tile_desc_${theme} ${isHighlighted ? `insights_week_tile_title_highlighted_${theme}` : ''}`}>{description}</p>
    </div>
  );
}

export default InsightsForTheWeekTile;
