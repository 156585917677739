import React from 'react';
import { dialog } from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';
import Error from '../../common/Error/Error';
import { ReactComponent as SentImage } from '../../../images/Illustration.svg';

interface RequestSentProps {
  error: string;
}
/**
 * This screen shows the users colour energy
 * retrieved from the DB.
 */
function RequestSent({ error }: RequestSentProps) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column col-8">
      {error ? (
        <Error
          error={null}
          successCallback={() => null}
          message={error}
          theme="default"
        />
      ) : (
        <div>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <SentImage />
          </div>
          <h4 className="text-center">{t('ms-teams-send-request-title')}</h4>
          <p className="mb-3 text-center insightsFlowText">
            {t('ms-teams-send-request-body')}
          </p>
        </div>
      )}
      <button
        type="button"
        className="btn insightsText"
        style={{ color: '#1673A3', outline: 'none' }}
        onClick={() => dialog.url.submit('<close>')}
      >
        {' '}
        {t('ms-teams-send-request-back-button')}
      </button>
    </div>
  );
}

export default RequestSent;
