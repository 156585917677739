import { useEffect } from 'react';
import './App.css';

/**
 * Component that loads the consent link on a popup
 */
function OpenPopup() {
  useEffect(() => {
    window.location.href = (window.location.search || '').substring(1, window.location.search.length);
  }, []);

  return null;
}

export default OpenPopup;
