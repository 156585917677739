import React from 'react';

interface InsightsFlowTextProps {
  text: string;
}
function InsightsFlowText({ text }: InsightsFlowTextProps) {
  return (
    <div
      className="my-3 rounded rounded-sm"
      style={{ backgroundColor: '#E5F2F7', padding: '10px 16px' }}
    >
      <span className="insightsFlowTextProfileExists">{text}</span>
    </div>
  );
}

export default InsightsFlowText;
