import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, makeStyles } from '@fluentui/react-components';
import { tokens } from '@fluentui/react-theme';
import ConsentPopup from '../../ConsentPopup/ConsentPopup';
import { getErrorTextColour } from '../../../util/theme';

interface ErrorProps {
  error: number | null;
  consentScope?: string;
  successCallback(): void;
  message?: string;
  theme: string;
}

const useStyles = makeStyles({
  titleStyle: { marginBottom: tokens.spacingVerticalXS },
});

function Error({
  error,
  consentScope,
  successCallback,
  message,
  theme = 'default',
}: ErrorProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (error === 403 && consentScope) {
    return (
      <ConsentPopup
        consentScope={consentScope}
        successCallback={successCallback}
        theme={theme}
      />
    );
  }

  return (
    <div className="d-flex flex-column">
      <Label
        className={classes.titleStyle}
        style={{ color: getErrorTextColour(theme) }}
        size="large"
      >
        {' '}
        {t('ms-teams-error-title')}
      </Label>
      {message ? (
        <Label style={{ color: getErrorTextColour(theme) }}>
          {t('ms-teams-send-request-error-subtext')}
        </Label>
      ) : (
        <Label style={{ color: getErrorTextColour(theme) }}>
          {t('ms-teams-error-subtext')}
        </Label>
      )}
    </div>
  );
}

export default Error;
