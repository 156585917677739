/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectProfileDropdown from '../common/SelectProfileDropdown';
import { EnergyDisplayData, StatementEnhanced } from '../../global/types';
import DoHeading from '../common/Headings/Do';
import DontHeading from '../common/Headings/Dont';

interface SidePanelProps {
  theme: string;
  data: EnergyDisplayData;
  signedInUserEmail: string;
}

function SidePanel({ theme, data, signedInUserEmail }: SidePanelProps) {
  const { t } = useTranslation();
  const attendeesWithProfileToShare = data
    && data.people
    && (data.people || []).filter(
      (attendee) => attendee.colourEnergiesConfirmedAt && attendee.allowShareColourEnergies,
    );

  const userObject = (attendeesWithProfileToShare || []).reduce(
    (prev: any, user: any) => {
      // filer statements
      Object.assign(prev, {
        [user.displayName]: {
          communicationDos: (user.communicationDos || []).filter(
            (statement: StatementEnhanced) => statement.public
              || (signedInUserEmail === user.email && statement.personal),
          ),
          communicationDonts: (user.communicationDonts || []).filter(
            (statement: StatementEnhanced) => statement.public
              || (signedInUserEmail === user.email && statement.personal),
          ),
        },
      });
      return prev;
    },
    {},
  );

  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [commsDos, setCommsDos] = useState([]);
  const [commsDonts, setCommsDonts] = useState([]);

  useEffect(() => {
    if (selectedUser && selectedUser.value) {
      const userComms = userObject[(selectedUser as any).value];
      setSelectedUser(userComms);
      setCommsDos(userComms.communicationDos);
      setCommsDonts(userComms.communicationDonts);
    }
  }, [selectedUser, setSelectedUser]);

  return (
    <div
      className={`sidePanel sidePanel_${theme} d-flex flex-column p-2`}
      style={{ height: '100%', minHeight: '100vh', overflowY: 'scroll' }}
    >
      <h3 className={`avatarTitle_${theme} section_title pb-2`}>
        {t('ms-teams-comms-do-dont-tile-title')}
      </h3>
      <span className={`avatarTitle_${theme} select_attendee_text`}>
        {t('ms-teams-select-attendee')}
      </span>
      <SelectProfileDropdown
        setSelectedUser={setSelectedUser}
        theme={theme}
        options={(attendeesWithProfileToShare || []).map((person) => ({
          label: person.displayName,
          value: person.displayName,
          icon: person.profilePhoto,
        }))}
      />
      {selectedUser ? (
        <div className="d-flex flex-column" style={{ marginTop: 28 }}>
          <DoHeading theme={theme} />
          {commsDos.length > 0 ? (
            <div
              className="d-flex flex-column"
              style={{ marginBottom: 12, marginTop: 5 }}
            >
              {commsDos.map((statement: StatementEnhanced) => (
                <div
                  className={`side_panel_statement side_panel_statement_border_${theme}`}
                >
                  <span className={`avatarTitle_${theme}`}>
                    {statement.text}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <p className={`text_colour_${theme} pt-2`}>
              {t('ms-teams-comms-do-no-users-energies')}
            </p>
          )}
          <DontHeading theme={theme} />
          {commsDonts.length > 0 ? (
            <div
              className="d-flex flex-column"
              style={{ marginBottom: 12, marginTop: 5 }}
            >
              {commsDonts.map((statement: StatementEnhanced) => (
                <div
                  className={`side_panel_statement side_panel_statement_border_${theme}`}
                >
                  <span className={`avatarTitle_${theme}`}>
                    {statement.text}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <p className={`text_colour_${theme} pt-2`}>
              {t('ms-teams-comms-dont-no-users-energies')}
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default SidePanel;
