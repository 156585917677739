/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

interface CheckboxProps {
  label: string | null;
  id: string;
  name: string;
  value: boolean;
  theme: string;
  onClick: any;
}

function Checkbox({
  label, id, name, value, theme, onClick,
}: CheckboxProps) {
  return (
    <div className={`checkbox-wrapper checkbox-wrapper-${theme}`}>
      <input
        type="checkbox"
        name={name}
        id={id}
        defaultChecked={value}
        onClick={onClick}
      />
      {label ? (
        <label
          className={`checkbox-label pointer text_colour_${theme}`}
          htmlFor={id}
          style={{ marginBottom: 4 }}
        >
          {label}
        </label>
      ) : null}
    </div>
  );
}

export default Checkbox;
