/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useState, ReactNode } from 'react';

interface DropdownSectionProps {
  title: string;
  textContent: ReactNode;
  borderAbove?: boolean;
}

/**
 * This is a simple dropdown section, allows the use to click and
 * show the details of the section.
 */
function DropdownSection({ title, textContent, borderAbove = false }: DropdownSectionProps) {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`${!borderAbove ? 'dropdownSection' : ''} d-flex flex-column`}
      onClick={() => setShow(!show)}
    >
      <div
        className={`${
          borderAbove ? 'dropdownSectionAbove' : ''
        } d-flex justify-content-between w-100`}
      >
        <h4 className="insightsFlowDropdownText">{title}</h4>
        {show ? ( // the minus icon
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            style={{ color: '#1673A3' }}
            className="bi bi-dash-lg"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
            />
          </svg>
        ) : (
          // the plus icon
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            style={{ color: '#1673A3' }}
            className="bi bi-plus-md"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
            />
          </svg>
        )}
      </div>
      {show ? (
        <>
          {textContent}
          <br />
        </>
      ) : null}
    </div>
  );
}

export default DropdownSection;
