/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import {
  getSelectBackgroundColour,
  getSelectBorderColour,
  getSelectHoverBorderColour,
  getTextColour,
} from '../../../util/theme';

interface ContentsProps {
  icon: string;
  label: string;
}

function Contents({ icon, label }: ContentsProps) {
  return (
    <div className="d-flex flex-row">
      <Avatar
        image={{ src: `data:image/jpeg;base64, ${icon}` }}
        name={label}
        size={24}
      />
      <span style={{ marginLeft: 8 }}>{label}</span>
    </div>
  );
}

function Option(props: any) {
  const {
    data: { icon, label },
  } = props;
  return (
    <components.Option {...props} className="country-option">
      {Contents({ icon, label })}
    </components.Option>
  );
}

function SingleValue({ ...props }: any) {
  const {
    data: { icon, label },
  } = props;
  return (
    <components.SingleValue {...props}>
      {Contents({ icon, label })}
    </components.SingleValue>
  );
}

interface SelectProfileDropdownProps {
  theme: string;
  options: any;
  setSelectedUser: any;
}

function SelectProfileDropdown({
  theme,
  options,
  setSelectedUser,
}: SelectProfileDropdownProps) {
  const [selectedUser, setUser] = useState(options[0]);

  const handleChange = (value: any) => {
    setUser(value);
    setSelectedUser(value);
  };

  useEffect(() => setSelectedUser(options[0]), []);

  return (
    <div>
      <Select
        value={selectedUser}
        options={options}
        onChange={handleChange}
        styles={{
          ['control' as any]: (base: any, state: any) => ({
            ...base,
            backgroundColor: getSelectBackgroundColour(theme),
            borderRadius: 4,
            borderColor: state.isFocused
              ? getSelectHoverBorderColour(theme)
              : getSelectBorderColour(theme),
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
              borderColor: state.isFocused
                ? getSelectHoverBorderColour(theme)
                : getSelectBorderColour(theme),
              borderWidth: theme === 'contrast' ? 2 : 1,
            },
          }),
          input: (base) => ({
            ...base,
            color: getTextColour(theme),
          }),
          menu: (base) => ({
            ...base,
            borderRadius: 4,
            marginTop: 0,
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
          }),
          singleValue: (base) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            color: getTextColour(theme),
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)',
          }),
          option: (styles, { isDisabled, isSelected }) => {
            const bgColor = getSelectBackgroundColour(theme);
            return {
              ...styles,
              backgroundColor: bgColor,
              color: getTextColour(theme),
              ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                  ? isSelected
                    ? bgColor
                    : bgColor
                  : undefined,
              },
            };
          },
        }}
        components={{
          IndicatorSeparator: () => null,
          Option,
          SingleValue,
        }}
      />
    </div>
  );
}

export default SelectProfileDropdown;
