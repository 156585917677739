import React from 'react';
import moment from 'moment';
import { Avatar } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import CommsDoDont from '../CommsDoDont';
import StrengthsWeaknesses from '../StrengthsWeaknesses';
import Highlights from '../Highlights';
import EnergySplit from '../EnergySplit';
import { ColourEnergyValues, StatementEnhanced } from '../../../global/types';

interface ProfileBreakdowProps {
  theme: string;
  photo: string;
  displayName: string;
  createdAt: string;
  colourEnergyValues: ColourEnergyValues;
  fullProfile: boolean;
  yourProfile: boolean;
  highlights: StatementEnhanced[];
  strengths: StatementEnhanced[];
  weakness: StatementEnhanced[];
  communicationDos: StatementEnhanced[];
  communicationDonts: StatementEnhanced[];
  isCurrentUser: boolean;
}

function ProfileBreakdown({
  theme,
  photo,
  displayName,
  createdAt,
  colourEnergyValues,
  fullProfile,
  yourProfile,
  highlights,
  strengths,
  weakness,
  communicationDos,
  communicationDonts,
  isCurrentUser,
}: ProfileBreakdowProps) {
  const { t } = useTranslation();

  const filterHiddenStatements = (
    statements: StatementEnhanced[],
    isCurUser: boolean,
  ) => statements.filter(
    (statement) => statement.public || (statement.personal === true && isCurUser),
  );

  return (
    <div
      className={`bg_${theme}_card w-100 w-md-50`}
      // style={{ backgroundColor: 'red' }}
      // style={{ maxWidth: '50%', minWidth: '50%' }}
    >
      {/* Avatar, profile summary title, profile view count & energy split */}
      <div
        className="d-flex flex-column card_padding shadow_test"
        style={{
          paddingBottom: 0,
          paddingTop: 14,
          borderBottom: theme === 'contrast' ? 'solid 1px #ffffff' : '',
        }}
      >
        <div className="d-flex flex-row mb-2">
          <Avatar
            image={{ src: photo }}
            name={displayName}
            size={72}
            className="ms-0 ms-sm-3 ms-md-0 mb-2 mb-md-0"
          />
          <div className="ms-3 w-100 personal_profile_text_container">
            <h3
              data-hj-suppress
              className={`sub_${theme}_title`}
              style={{
                marginBottom: 2,
                fontWeight: 700,
                fontSize: 16,
                textOverflow: 'ellipsis',
              }}
            >
              {yourProfile
                ? t('ms-teams-personal-profile-your-summary')
                : `${displayName}${t(
                  'ms-teams-personal-profile-possesives',
                )} ${t('ms-teams-personal-profile-compare-summary')}`}
            </h3>
            <p
              className={`avatarParagraph_${theme} personal_profile_subtext`}
              style={{ marginBottom: 0, textOverflow: 'ellipsis' }}
            >
              {t('ms-teams-personal-dashboard-colours-confirmed-title', {
                confirmedAt: moment
                  .utc(createdAt)
                  .format('DD MMM YYYY'),
              })}
            </p>
          </div>
        </div>
        <EnergySplit
          theme={theme}
          colourEnergyValues={colourEnergyValues}
          fullProfile={fullProfile}
        />
      </div>
      {/* Scrollable section */}
      <div
        className={`d-flex flex-column card_padding compare_profiles_statements_bg_${theme} profile_breakdown_scrollable_area`}
        // style={{ maxHeight: 350, overflowY: 'scroll' }}
      >
        <Highlights
          id="personal_profile_highlights"
          theme={theme}
          values={filterHiddenStatements(highlights, isCurrentUser) || []}
        />
        <StrengthsWeaknesses
          id="personal_profile_strengths_weaknesses"
          theme={theme}
          compareProfile
          strengths={filterHiddenStatements(strengths, isCurrentUser) || []}
          weaknesses={filterHiddenStatements(weakness, isCurrentUser) || []}
        />
        <CommsDoDont
          id="personal_profile_comms"
          theme={theme}
          compareProfile
          dos={filterHiddenStatements(communicationDos, isCurrentUser) || []}
          donts={
            filterHiddenStatements(communicationDonts, isCurrentUser) || []
          }
        />
      </div>
    </div>
  );
}

export default ProfileBreakdown;
