/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { getSelectBackgroundColour, getCommonSelectBorderColour, getTextColour } from '../../../util/theme';

interface SelectProps {
  theme: string;
  values: Array<{ title: string; value: string }>;
  onClick: any;
  defaultValue: string;
}

function Select({
  theme, values, onClick, defaultValue,
}: SelectProps) {
  return (
    <select
      className={`form-select pointer selectBorder_${theme}`}
      style={{
        minWidth: 250,
        backgroundColor: getSelectBackgroundColour(theme),
        borderColor: getCommonSelectBorderColour(theme),
        color: getTextColour(theme),
        height: 32,
        padding: 0,
        paddingLeft: 12,
      }}
      aria-label="Select options"
      onChange={(e) => onClick(e.target.value)}
      value={defaultValue}
    >
      {values.map(({ title, value }) => (
        <option value={value} selected={defaultValue === value}>
          {title}
        </option>
      ))}
    </select>
  );
}

export default Select;
