import React from 'react';
import Spinner from '../common/Spinners/Spinner';

interface TabPlaceholder {
  theme: string;
  text: string;
  fetchingText: string;
}

function TabPlaceholder({ theme, text, fetchingText }: TabPlaceholder) {
  return (
    <div className="d-flex flex-column mb-5">
      <div
        className={`bg_${theme}_card summary_section_shadow`}
        style={{ paddingBottom: 90 }}
      >
        <Spinner theme={theme} animationName="blocks" text={text} />
      </div>
      <div className="d-flex flex-column flex-md-row mt-3">
        <div
          className={`bg_${theme}_card summary_section_shadow w-md-50 w-100 me-md-2 me-0`}
          style={{ paddingBottom: 150 }}
        >
          <Spinner theme={theme} animationName="wavy" text={fetchingText} />
        </div>
        <div
          className={`bg_${theme}_card summary_section_shadow w-md-50 w-100 ms-md-2 ms-0 mt-3 mt-md-0`}
          style={{ paddingBottom: 150 }}
        >
          <Spinner theme={theme} animationName="wavy" text={fetchingText} />
        </div>
      </div>
    </div>
  );
}

export default TabPlaceholder;
