/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { app } from '@microsoft/teams-js';
import React, { useContext, useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import SummaryCard from './SummaryCard/SummaryCard';
import CommunicationCard from './CommunicationCard/CommunicationCard';
import { getChannelMembers, getChatInfo, attachPeopleAvatarAndPresence } from '../services/learnersService';
import TabPlaceholder from './placeholders/TabPlaceholder';
import { EnergyDisplayData, Person } from '../global/types';
import { UserContext } from '../context/user-context';
import CommunicationDosDontsTile from './CommunicationDosDontsTile';
import SidePanel from './SidePanel';
import SidePanelPlaceholder from './placeholders/SidePanelPlaceholder';

type MembersState = {
  data: EnergyDisplayData | null;
  error: number | null;
};

/**
 * The tab content displayed in Chats, Meetings and Channels with
 * important Insights information.
 */
function ChatTab() {
  const { t } = useTranslation();
  const context = useContext(UserContext) || { themeState: 'default' };
  const [frameContext, setFrameContext] = useState('content');

  const fetchSignedInUser = async () => {
    const fc = app.getFrameContext();
    setFrameContext(fc || 'content');
    if (context && context?.updateUser) {
      await context?.updateUser();
    }
  };

  // Fetch the User info
  useEffect(() => {
    fetchSignedInUser();
    hotjar.stateChange('/chat-or-meeting');
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadedAvatar, setHasloadedAvatar] = useState(false);
  const [members, setMembers] = useState<MembersState>({
    data: null,
    error: null,
  });
  const [conscious, setConscious] = useState(true);
  const [title, setTitle] = useState('');
  const [noColourEnergyText, setNoColourEnergyText] = useState('');

  /**
   * Use App context to retrieve information about this tab, specifically
   * the chat id or channel id which is necessary to make the Graph API call to retrieve chat
   * information e.g. members in chat and or channel / associated user info.
   */
  const getMembers = async () => {
    setIsLoading(true);
    const data = await app.getContext();

    /**
     * Applies to tabs added to the following: one to one chats, group chats,
     * pre-meetings & during meetings.
     * */
    if (data.chat && data.chat.id) {
      const chatMembers = await getChatInfo(data.chat.id);

      if (chatMembers.status === 200) {
        setMembers({
          data: chatMembers.data as EnergyDisplayData,
          error: null,
        });

        setNoColourEnergyText(
          data.meeting
            ? () => t('ms-teams-summary-card-meeting-no-colour-energy')
            : () => t('ms-teams-summary-card-chat-no-colour-energy'),
        );

        setTitle(data.meeting ? () => t('ms-teams-summary-card-meeting-title') : () => t('ms-teams-summary-card-chat-title'));
      } else {
        setMembers({
          data: null,
          error: chatMembers.status || null,
        });
      }
    } else if (data && data.team && data.team.groupId && data.channel && data.channel.id) {
      // refers to tabs setup on teams channels
      const channelMembers = await getChannelMembers(data.team.groupId, data.channel.id);

      if (channelMembers.status === 200) {
        setMembers({
          data: channelMembers.data as EnergyDisplayData,
          error: null,
        });

        setNoColourEnergyText(() => t('ms-teams-summary-card-channel-no-colour-energy'));
        setTitle(() => t('ms-teams-summary-card-channel-title'));
      } else {
        setMembers({
          data: null,
          error: channelMembers.status || null,
        });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    const getAvatarAndPresences = async (people: EnergyDisplayData) => {
      const attachAvatarAndPresences = await attachPeopleAvatarAndPresence(
        people as EnergyDisplayData,
      );

      if (attachAvatarAndPresences.status === 200) {
        const newMembers = {
          ...members.data,
          people: attachAvatarAndPresences.data as Person,
        };

        setMembers({
          data: newMembers as unknown as EnergyDisplayData,
          error: null,
        });
      }
    };

    if (!hasLoadedAvatar && members && members.data && members.data !== null) {
      getAvatarAndPresences(members.data as EnergyDisplayData);

      setHasloadedAvatar(true);
    }
  }, [members]);

  const onToggleClicked = () => {
    setConscious(!conscious);
  };

  if (frameContext === 'sidePanel' && members && members.data) {
    return (
      <SidePanel
        theme={context.themeState}
        data={members.data}
        signedInUserEmail={context.user?.email || ''}
      />
    );
  }

  return (
    <div
      className={`px-3 py-3 pb-1 ${
        frameContext !== 'sidePanel'
          ? `bg_${context.themeState}_main`
          : 'side_panel_placeholder_default_background'
      }  `}
      style={{ height: '100%', minHeight: '100vh' }}
    >
      {!isLoading && context.user ? (
        <>
          <SummaryCard
            loggedInUserName={context.user?.displayName}
            loggedInUserConfirmedDate={context.user?.colourEnergiesConfirmedAt}
            loggedInUserColourEnergy={context.user?.consciousColourEnergyValues}
            title={title}
            subtext={t('ms-teams-summary-card-meeting-subtitle')}
            people={
              members && members.data && members.data.people
                ? members.data.people
                : []
            }
            error={members.error}
            theme={context.themeState}
            onSuccessConsent={getMembers}
            onConsciousToggleClicked={onToggleClicked}
            conscious={conscious}
            collectiveColourEnergyValues={
              members
              && members.data
              && members.data.collectiveColourEnergyValues
            }
          />
          {members
          && members.data
          && members.data.collectiveColourEnergyValues ? (
            <div className="d-flex flex-md-row flex-column mt-0 mt-md-3">
              <CommunicationCard
                colourEnergyValues={members.data.collectiveColourEnergyValues}
                noColourEnergyText={noColourEnergyText}
                theme={context.themeState}
                colourEnergyUserCount={members.data.colourEnergyUserCount}
                totalUsers={members.data.people.length}
              />
              <CommunicationDosDontsTile
                theme={context.themeState}
                communicationStatements={members.data.communicationStatements}
                people={
                  members && members.data && members.data.people
                    ? members.data.people
                    : []
                }
                usersAndLeadEnergy={members.data.people.reduce((prev, user) => {
                  Object.assign(prev, {
                    [user.displayName]: t(
                      `ms-teams-energy-display-name-${user.leadColourEnergy}`,
                    ),
                  });
                  return prev;
                }, {})}
                colourEnergyUserCount={members.data.colourEnergyUserCount}
                totalUsers={members.data.people.length}
              />
            </div>
            ) : null}
        </>
      ) : frameContext !== 'sidePanel' ? (
        <TabPlaceholder
          theme={context.themeState}
          text={t('ms-teams-loading-colour-blocks')}
          fetchingText={t('ms-teams-fetching-profile-content')}
        />
      ) : (
        <SidePanelPlaceholder
          theme={context.themeState}
          text={t('ms-teams-fetching-profile-content')}
        />
      )}
    </div>
  );
}

export default ChatTab;
