/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { hotjar } from 'react-hotjar';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { app } from '@microsoft/teams-js';
import { ColourEnergyValues } from '../../../global/types';
import { installMeetingTab } from '../../../services/learnersService';
import EnergySplit from '../EnergySplit';

interface MeetingCardProps {
  theme: string;
  title: string;
  start: string;
  end: string;
  attendees: number;
  collectiveColourEnergyValues: ColourEnergyValues;
  deeplink: string;
  meetingId: string;
  isOnlineMeeting: boolean;
}

/**
 * The individual meeting cards and the associated
 * details.
 */
function MeetingCard({
  theme,
  title,
  start,
  end,
  attendees,
  collectiveColourEnergyValues,
  deeplink,
  meetingId,
  isOnlineMeeting,
}: MeetingCardProps) {
  const { t } = useTranslation();
  const FULL_PROFILE = true; // replace with real data from API.
  const [loading, setLoading] = useState(false);
  const [errorAddingTab, setErrorAddingTab] = useState(false);
  const [errorAddingTab409, set409Error] = useState(false);
  const [deepLink, setDeepLink] = useState(deeplink);

  const noColoursShared = Object.values(collectiveColourEnergyValues).every(
    (v) => v === 0,
  );

  return (
    <div className="flex-column pe-sm-3 mb-sm-0 mb-2 col-12 col-md-6 col-lg-3">
      <div
        className={`meetings_${theme}_card p-3`}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <h5
          className={`pb-0 text-truncate sub_meeting_${theme}`}
          style={{ margin: 0, marginBottom: 4 }}
        >
          {title}
        </h5>
        <span className={`meeting_${theme}_date`}>
          {moment.utc(start).local().format('DD MMM YYYY HH:mm')}
          {' '}
          -
          {' '}
          {moment.utc(end).local().format('HH:mm')}
        </span>
        <div className="border-top mt-2" style={{ borderColor: '#E0E0E0' }} />
        <p className={`mt-2 sub_${theme}_title mb-md-5 mb-3`}>
          {attendees}
          {' '}
          {t('ms-teams-meeting-section-people-invited')}
        </p>
        {collectiveColourEnergyValues && !noColoursShared ? (
          <>
            <EnergySplit
              theme={theme}
              colourEnergyValues={collectiveColourEnergyValues}
              fullProfile={FULL_PROFILE}
            />
            <button
              type="button"
              disabled={!isOnlineMeeting}
              className={`${
                deeplink ? 'hideDeeplinkButtonMobile' : ''
              } col-12 mt-1 meetingButton_${theme} ${
                deepLink
                  ? 'meetingButton'
                  : `meetingNoLinkButton meeting_${theme}_no_link_button ${
                    !isOnlineMeeting ? 'disabled' : 'pointer'
                  }`
              }`}
              onClick={async () => {
                setLoading(true);
                hotjar.stateChange('/meeting-tab');

                if (deepLink) {
                  app.openLink(deepLink);
                } else {
                  try {
                    const generatedDeepLink = await installMeetingTab(
                      meetingId,
                    );
                    setLoading(false);
                    if (generatedDeepLink.status === 200) {
                      setDeepLink(generatedDeepLink.data as string);
                    } else {
                      setErrorAddingTab(true);
                    }
                  } catch (e: any) {
                    setLoading(false);
                    if (e.response.status === 409) set409Error(true);
                  }
                }
              }}
            >
              {loading ? (
                <span>{t('ms-teams-loading')}</span>
              ) : !isOnlineMeeting ? (
                t('ms-teams-meeting-section-unable-to-add-tab')
              ) : deepLink ? (
                t('ms-teams-meeting-section-view-colour-energies')
              ) : (
                t('ms-teams-meeting-section-view-colour-energies-no-link')
              )}
            </button>
          </>
        ) : (
          <p
            className={`avatarLink ${theme}_sub_title mb-0 mb-lg-2`}
            style={{ marginTop: 'auto' }}
          >
            {t('ms-teams-no-colours-shared')}
          </p>
        )}
        {deeplink && !noColoursShared ? (
          <p
            className={`avatarLink avatarParagraph_${theme} deeplinkMobileHelpText`}
          >
            {t('ms-teams-deeplink-mobile-help-text')}
          </p>
        ) : null}

        {errorAddingTab409 ? (
          <p className={`meetings_error_adding_tab_${theme} mt-2`}>
            {t('ms-teams-existing-tab-error')}
          </p>
        ) : null}
        {errorAddingTab ? (
          <p className={`meetings_error_adding_tab_${theme} mt-2`}>
            {t(
              'ms-teams-meeting-section-view-colour-energies-error-adding-tab',
            )}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default MeetingCard;
