import React, { useContext } from 'react';
import { Avatar } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/user-context';

interface StatementProps {
  statement: string;
  images: Array<{ image: string; displayName: string }>;
  profilesText: string;
  threePlusProfiles: boolean;
}

function Statement({
  statement,
  images,
  profilesText,
  threePlusProfiles,
}: StatementProps) {
  const { t } = useTranslation();
  const context = useContext(UserContext) || { themeState: 'default' };

  return (
    <div
      className={`statement personal_profile_spacer_${context.themeState}`}
      style={{
        borderWidth: 1,
        borderStyle: 'solid',
        flexWrap: 'wrap',
      }}
    >
      <p
        className={`small_text text_colour_${context.themeState}`}
        style={{ marginBottom: 4 }}
      >
        {statement}
      </p>
      <div className="d-flex flex-row">
        <div className="d-flex flex-row" style={{ marginRight: 3 }}>
          {images.map((data) => (
            <Avatar
              image={{
                src:
                  data && data.image !== null
                    ? `data:image/jpeg;base64, ${data.image}`
                    : undefined,
              }}
              name={(data && data.displayName) || ''}
              style={{ marginRight: '-5px' }}
              size={24}
            />
          ))}
          {threePlusProfiles ? (
            <div className="threePlusProfile threePlusProfileText">
              {t('ms-teams-comms-do-dont-three-plus')}
            </div>
          ) : null}
        </div>
        <p
          className={`where_text text_colour_${context.themeState}`}
          style={{ margin: 0, marginLeft: 6 }}
        >
          {profilesText}
        </p>
      </div>
    </div>
  );
}

export default Statement;
