import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDoDontIconColour } from '../../../util/theme';

interface DontHeadingProps {
  theme: string;
}

function DontHeading({ theme }: DontHeadingProps) {
  const { t } = useTranslation();
  return (
    <h3
      className={`section_title dont_box_border_${theme} mt-3 mt-md-0 api_content_heading_default`}
    >
      <svg
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: 6, marginBottom: 2 }}
      >
        <g id="Icons / Regular / Close">
          <path
            id="Vector"
            d="M1 9L9.33333 1"
            stroke={getDoDontIconColour(theme, false)}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M9.33333 9L1 1"
            stroke={getDoDontIconColour(theme, false)}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
      {t('ms-teams-personal-profile-dont')}
    </h3>
  );
}

export default DontHeading;
