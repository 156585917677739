/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTextLabelColour } from '../../../util/theme';
import { StatementValues } from '../../../global/types';

interface StrengthsWeaknessesProps {
  id?: string;
  theme: string;
  strengths: StatementValues[];
  weaknesses: StatementValues[];
  compareProfile?: boolean;
}
function List(items: StatementValues[], theme: string) {
  return (
    <ul className="col-12 pb-0">
      {items.map((item) => (
        <li
          key={item.text}
          className={`personal_profile_subtext avatarTitle_${theme}`}
        >
          {item.text}
        </li>
      ))}
    </ul>
  );
}

function StrengthsWeaknesses({
  id,
  theme,
  strengths = [],
  weaknesses = [],
  compareProfile = false,
}: StrengthsWeaknessesProps) {
  const { t } = useTranslation();
  const [all, setAll] = useState(false);

  const handleShowAll = () => setAll(!all);

  return (
    <div className="d-flex flex-column" id={id} style={{ marginTop: 8 }}>
      <div
        className={`d-flex ${
          compareProfile ? 'flex-column' : 'flex-md-row flex-column'
        } `}
      >
        <div className="strengths_weakness_column">
          <h3
            className={`section_title avatarParagraph_${theme} box_border_${theme} api_content_heading_default`}
          >
            {t('ms-teams-personal-profile-strengths')}
          </h3>
          {strengths.length === 0 ? (
            <ul className="col-12 pb-0">
              <li className={`personal_profile_subtext avatarTitle_${theme}`}>
                {t('ms-teams-profile-no-statements')}
              </li>
            </ul>
          ) : (
            List(all ? strengths : strengths.slice(0, 2), theme)
          )}
        </div>
        <div className="strengths_weakness_column">
          <h3
            className={`section_title avatarParagraph_${theme} box_border_${theme} api_content_heading_default`}
          >
            {t('ms-teams-personal-profile-possible-weaknesses')}
          </h3>
          {weaknesses.length === 0 ? (
            <ul className="col-12 pb-0">
              <li className={`personal_profile_subtext avatarTitle_${theme}`}>
                {t('ms-teams-profile-no-statements')}
              </li>
            </ul>
          ) : (
            List(all ? weaknesses : weaknesses.slice(0, 2), theme)
          )}
        </div>
      </div>
      {weaknesses.length > 2 || strengths.length > 2 ? (
        <button
          type="button"
          onClick={handleShowAll}
          className=" personal_dashboard_button"
          style={{ color: getTextLabelColour(theme) }}
        >
          {all
            ? t('ms-teams-personal-profile-show-less')
            : t('ms-teams-personal-profile-show-all')}
        </button>
      ) : null}
      <div
        className={`personal_profile_spacer personal_profile_spacer_${theme}`}
      />
    </div>
  );
}

export default StrengthsWeaknesses;
