import React from 'react';

interface InsightsFlowTextNoActionButtonProps {
  text: string | string[] | null;
}

function InsightsFlowTextNoActionButton({
  text,
}: InsightsFlowTextNoActionButtonProps) {
  return (
    <>
      {' '}
      {Array.isArray(text) ? (
        text.map((t) => <p className="insightsFlowText">{t}</p>)
      ) : (
        <p className="insightsFlowText">{text}</p>
      )}
    </>
  );
}

export default InsightsFlowTextNoActionButton;
