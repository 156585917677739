import React from 'react';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import DropdownSection from '../../common/DropdownSection';
import TextInput from '../../common/TextInput';
import InsightsButton from '../../common/InsightsButton/InsightsButton';
import { requestType } from '../../../config/constants';
import InsightsFlowTextNoActionButton from './InsightsFlowTextNoActionButton';

type ProfileRequestSent = (request: string) => void;

interface HelpSectionProps {
  text: string | string[] | null;
  btnText: string;
  postProfileRequest: ProfileRequestSent;
}

interface SetupColourEnergiesProps {
  emailAddress: string | undefined;
  postProfileRequest: ProfileRequestSent;
  hasNominatePeople: boolean | null;
  lng: string;
}

interface InsightsFlowTextProps {
  text: string | string[] | null;
  btnText: string;
  onClick: () => void;
}

function InsightsFlowText({ text, btnText, onClick }: InsightsFlowTextProps) {
  return (
    <>
      {Array.isArray(text) ? (
        text.map((t) => <p className="insightsFlowText">{t}</p>)
      ) : (
        <p className="insightsFlowText">{text}</p>
      )}
      <div className="col-12 col-sm-8">
        <InsightsButton
          title={btnText}
          onClick={onClick}
          disabledFocusable={false}
        />
      </div>
    </>
  );
}

function NoInsightsProfileContent({
  text,
  btnText,
  postProfileRequest,
}: HelpSectionProps) {
  return (
    <InsightsFlowText
      text={text}
      btnText={btnText}
      onClick={() => {
        hotjar.event('no-insights-profile-email');
        postProfileRequest(requestType.NEW_PROFILE);
      }}
    />
  );
}

function ProfileThroughDifferentEmail({
  text,
  btnText,
  postProfileRequest,
}: HelpSectionProps) {
  return (
    <InsightsFlowText
      text={text}
      btnText={btnText}
      onClick={() => {
        hotjar.event('profile-through-different-email');
        postProfileRequest(requestType.MERGE_REQUEST);
      }}
    />
  );
}

function NoColourOrderShown({
  text,
  btnText,
  postProfileRequest,
}: HelpSectionProps) {
  return (
    <InsightsFlowText
      text={text}
      btnText={btnText}
      onClick={() => {
        hotjar.event('profile-colour-order-not-shown-email');
        postProfileRequest(requestType.FIND_PROFILE);
      }}
    />
  );
}

/**
 * This component handles the input of the users energy
 * as well as provide information on what to do.
 */
function SetupColourEnergies({
  emailAddress,
  hasNominatePeople,
  postProfileRequest,
  lng,
}: SetupColourEnergiesProps) {
  const { t } = useTranslation('translation', { lng });
  return (
    <div className="d-flex flex-column">
      <h3 className="mb-3">{t('ms-teams-insights-flow-no-energies-title')}</h3>
      <p className="mb-3 insightsFlowText">
        {t('ms-teams-insights-flow-no-energies-subtext')}
      </p>
      <p className="mb-2" style={{ fontWeight: 600, fontStyle: 'normal' }}>
        {t('ms-teams-insights-flow-no-energies-email-title')}
      </p>
      <TextInput readOnly value={emailAddress || ''} />
      <div style={{ padding: 10 }} />
      <DropdownSection
        title={t(
          'ms-teams-insights-flow-no-energies-drop-section-title-no-profile',
        )}
        textContent={
          hasNominatePeople ? (
            <NoInsightsProfileContent
              text={[
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-no-profile-part-1',
                ),
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-no-profile-part-2',
                ),
              ]}
              btnText={t(
                'ms-teams-insights-flow-no-energies-drop-section-button-no-profile',
              )}
              postProfileRequest={() => postProfileRequest(
                t(
                  'ms-teams-insights-flow-support-request-sub-heading-no-profile',
                ),
              )}
            />
          ) : (
            <InsightsFlowTextNoActionButton
              text={[
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-no-profile-no-representative-1',
                ),
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-no-profile-no-representative-2',
                ),
              ]}
            />
          )
        }
      />
      <DropdownSection
        title={t(
          'ms-teams-insights-flow-no-energies-drop-section-title-different-email',
        )}
        textContent={
          hasNominatePeople ? (
            <ProfileThroughDifferentEmail
              text={[
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-different-email-1',
                ),
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-different-email-2',
                ),
              ]}
              btnText={t(
                'ms-teams-insights-flow-no-energies-drop-section-button-different-email',
              )}
              postProfileRequest={() => postProfileRequest(
                t(
                  'ms-teams-insights-flow-support-request-sub-heading-profile-using-different-email',
                ),
              )}
            />
          ) : (
            <InsightsFlowTextNoActionButton
              text={[
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-different-email-no-representative-1',
                ),
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-different-email-no-representative-2',
                ),
              ]}
            />
          )
        }
      />
      <DropdownSection
        title={t(
          'ms-teams-insights-flow-no-energies-drop-section-title-colour-order',
        )}
        textContent={
          hasNominatePeople ? (
            <NoColourOrderShown
              text={[
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-colour-order-1',
                ),
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-colour-order-2',
                ),
              ]}
              btnText={t(
                'ms-teams-insights-flow-no-energies-drop-section-button-colour-order',
              )}
              postProfileRequest={() => postProfileRequest(
                t(
                  'ms-teams-insights-flow-support-request-sub-heading-profile-colour-order-not-shown',
                ),
              )}
            />
          ) : (
            <InsightsFlowTextNoActionButton
              text={[
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-profile-wasnt-found-no-representative-1',
                ),
                t(
                  'ms-teams-insights-flow-no-energies-drop-section-body-profile-wasnt-found-no-representative-2',
                ),
              ]}
            />
          )
        }
      />
    </div>
  );
}

export default SetupColourEnergies;
