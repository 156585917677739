import React from 'react';
import { getTextLabelColour } from '../../../util/theme';
import './SmallSpinner.css';

interface SmallSpinnerProps {
  theme: string
}

/**
 * The main page spinner e.g when calling
 * MS graph API information for a page.
 * @returns Spinner JSX
 */
function SmallSpinner({ theme }: SmallSpinnerProps) {
  return (
    <div className="d-flex align-items-center">
      <span
        className="loader"
        style={{ color: getTextLabelColour(theme) }}
      />
    </div>
  );
}

export default SmallSpinner;
