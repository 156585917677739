/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { getBackgroundColour } from '../../../util/theme';

interface RadioProps {
  label: string;
  id: string;
  name: string;
  defaultValue: boolean;
  theme: string;
  onClick: any;
}

function Radio({
  label, id, name, defaultValue, theme, onClick,
}: RadioProps) {
  return (
    <div className={`form-check ${theme}_radio`}>
      <input
        onClick={() => onClick(id)}
        style={{ backgroundColor: getBackgroundColour(theme) }}
        className="form-check-input"
        type="radio"
        name={name}
        id={id}
        defaultChecked={defaultValue}
      />
      <label
        className={`form-check-label pointer text_colour_${theme}`}
        htmlFor={id}
        style={{ marginLeft: 5 }}
      >
        {label}
      </label>
    </div>
  );
}

export default Radio;
