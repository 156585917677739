/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ColourEnergyValues } from '../../../global/types';

import {
  chartColours,
  chartGridColour,
  chartLabelColour,
  chartLabelYAxisTickColour,
} from '../../../util/theme';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

interface DashboardBoxProps {
  colourEnergyValues: ColourEnergyValues;
  theme: string;
}

/**
 * Shows the users colour energies in a chart and shows some
 * helpful do's and dont's when communicating with them. This
 * component is displayed via a MS teams task module (modal).
 */
function DashboardBox({
  colourEnergyValues, // example input: {blue: 53, green: 23, yellow: 77, red: 32}
  theme,
}: DashboardBoxProps) {
  const { t } = useTranslation();

  ChartJS.defaults.color = chartLabelColour(theme);
  ChartJS.defaults.font.size = 12;

  const options = {
    scaleFontColor: '#FFFFFF',
    responsive: true,
    scales: {
      x: {
        ticks: {
          font: {
            weight: 700,
          },
        },
        grid: {
          display: false,
        },
        border: {
          color: chartGridColour(theme),
        },
      },
      y: {
        border: {
          color: 'rgba(0,0,0,0)',
        },
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (val: number) => {
            if (val === 0) return;
            return `${val}%`;
          },
          color: chartLabelYAxisTickColour(theme),
        },
        grid: {
          color: chartGridColour(theme),
        },
      },
    },
  };

  const labels = [
    t('ms-teams-energy-display-name-coolBlueEnergy'),
    t('ms-teams-energy-display-name-fieryRedEnergy'),
    t('ms-teams-energy-display-name-sunshineYellowEnergy'),
    t('ms-teams-energy-display-name-earthGreenEnergy'),
  ];

  const data = {
    labels,
    datasets: [
      {
        data: [
          colourEnergyValues.coolBlueEnergy,
          colourEnergyValues.fieryRedEnergy,
          colourEnergyValues.sunshineYellowEnergy,
          colourEnergyValues.earthGreenEnergy,
        ],
        backgroundColor: chartColours(theme),
      },
    ],
  };

  return (
    <div className="flex-column">
      <div className="chartContainer" style={{ maxHeight: 350, width: '100%' }}>
        <Bar options={options as any} data={data} />
      </div>
    </div>
  );
}

export default DashboardBox;
