import { useState, useEffect } from 'react';

const useTabSize = () => {
  const [tabSize, setTabSize] = useState({
    tabWidth: window.innerWidth,
    tabHeight: window.innerHeight,
  });

  useEffect(() => {
    const resize = () => {
      setTabSize({
        tabWidth: window.innerWidth,
        tabHeight: window.innerHeight,
      });
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return tabSize;
};

export default useTabSize;
