/* eslint-disable default-param-last */
import axios from 'axios';
import { app } from '@microsoft/teams-js';
import { API_BASE_URL } from './api';

/**
 *
 * api function()
 * wrapper around the api sauce create object
 * need to check on each call whether the auth cookie is set,
 * if it is, we want to send the token up with each request
 * if it isn't, our AuthContext context will bounce the user
 * out of the app.
 *
 * method: get, post, put, delete
 * endpoint: the api we are looking to hit eg. "/auth/verify"
 * headers: http headers to add to the request
 * data: object containing our request body data
 * language: string containing the ISO language code
 *
 * @param {string}  method
 * @param {string}  url
 * @param {string}  endpoint
 * @param {obj}     headers
 * @param {obj}     data
 */

const api = async (
  method: string,
  endpoint: string,
  headers: object,
  data?: object | undefined,
  url: string | null = null,
  config: object = {},
) => {
  const teamsAppData = await app.getContext();
  const lng = teamsAppData.app.locale;
  const lngCode = lng && lng.slice(0, 2);

  const apiInstance = axios.create({
    baseURL: url || API_BASE_URL,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Language': lngCode || 'en',
      ...headers,
    },
  });

  const result = await apiInstance[method as 'get' | 'post'](
    endpoint,
    data,
    config,
  );
  return result;
};

export default api;
