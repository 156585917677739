/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useDropzone, ErrorCode, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { getTextLabelColour } from '../../../util/theme';

interface FileUploadProps {
  btnText: string;
  fileUploadFunction: any;
  theme: string;
}

function FileUpload({ btnText, fileUploadFunction, theme }: FileUploadProps) {
  const { t } = useTranslation();

  const ErrorCustomMessage = (errorCode: string) => {
    switch (errorCode) {
      case ErrorCode.FileInvalidType:
        return t('ms-teams-avatar-add-photo-error-invalid-file-type');
      case ErrorCode.FileTooLarge:
      case ErrorCode.FileTooSmall:
        return t('ms-teams-avatar-add-photo-error-file-too-large');
      case ErrorCode.TooManyFiles:
        return t('ms-teams-avatar-add-photo-error-to-many-files');
      default:
        return t('ms-teams-avatar-add-photo-error-default');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    onDrop: (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
      if (fileRejections.length) {
        const { errors } = fileRejections[0];
        fileUploadFunction(ErrorCustomMessage(errors[0].code));
      } else {
        fileUploadFunction(acceptedFiles[0]);
      }
    },
  });

  return (
    <div className="d-flex flex-column align-items-center" {...getRootProps()} style={{ zIndex: 1 }}>
      <input {...getInputProps()} />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        style={{ color: getTextLabelColour(theme), marginBottom: 2 }}
        className="bi bi-plus-md"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
        />
      </svg>
      <p style={{ color: getTextLabelColour(theme), textAlign: 'center' }}>{btnText}</p>
    </div>
  );
}

export default FileUpload;
