import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDoDontIconColour } from '../../../util/theme';

interface DoHeadingProps {
  theme: string;
}

function DoHeading({ theme }: DoHeadingProps) {
  const { t } = useTranslation();
  return (
    <h3
      className={`section_title do_box_border_${theme} api_content_heading_default`}
    >
      <svg
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: 6 }}
      >
        <g id="Icons / Regular / Check 1">
          <path
            id="Vector"
            d="M9.33333 0.833008L3.7437 8.81782C3.66995 8.9238 3.57208 9.01073 3.45814 9.07145C3.3442 9.13218 3.21746 9.16496 3.08837 9.16709C2.95928 9.16923 2.83153 9.14065 2.71564 9.08373C2.59976 9.0268 2.49906 8.94315 2.42185 8.83967L1 6.94412"
            stroke={getDoDontIconColour(theme, true)}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
      {t('ms-teams-personal-profile-do')}
    </h3>
  );
}

export default DoHeading;
