/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

interface ToggleProps {
  value: boolean;
  onClick: any;
  theme: string;
}

function Toggle({ value = false, onClick, theme }: ToggleProps) {
  const [isToggled, setIsToggled] = useState<boolean>(value);
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isToggled}
        onChange={() => {
          setIsToggled(!isToggled);
          onClick();
        }}
      />
      <div className={`slider slider_${theme}`} />
    </label>
  );
}

export default Toggle;
