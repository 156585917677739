import React from 'react';
import { ReactComponent as Logo } from '../../../images/Logo.svg';

function InsightsLogoWhite() {
  return (
    <div className="mb-4">
      <Logo />
    </div>
  );
}

export default InsightsLogoWhite;
