/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ColourEnergyValues } from '../../../global/types';
// import { ReactComponent as TranslateIcon } from '../../../images/TranslateIcon.svg';
import EnergySplit from '../EnergySplit';
// import { mapTeamsLocaleLanguageToLanguage } from '../../../util/mapLanguageCode';

interface ColourEnergyBreakdownProps {
  displayName: string;
  profileDate: string;
  // languageCode: string;
  // pronoun: string;
  chapters: Array<string>;
  fullProfile: boolean;
  colourEnergyValues: ColourEnergyValues;
  lng: string;
}

/**
 * This is the content displayed within the setup modal. Will show varying content for
 * the two versions of the Insights profile (Full & explore).
 */
function ColourEnergyBreakdown({
  displayName,
  profileDate,
  // languageCode,
  // pronoun,
  chapters,
  fullProfile,
  colourEnergyValues,
  lng,
}: ColourEnergyBreakdownProps) {
  const { t } = useTranslation('translation', { lng });
  // const capitliseFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
  return (
    <div className="colourEnergyBreakdownCard mt-4 mb-4">
      <h3 data-hj-suppress className="colourEnergyBreakdownTitle">
        {fullProfile
          ? t('ms-teams-energy-block-insights-discovery')
          : t('ms-teams-energy-block-insights-explore')}{' '}
        {displayName} {moment.utc(profileDate).local().format('Do MMM YYYY')}
      </h3>
      {/* <h3 className="colourEnergyBreakdownSubtitle"> */}
      {/* <TranslateIcon /> */}
      {/* <span
          className="colourEnergyBreakdownSubtitle"
          style={{ marginBottom: 15 }}
        >
          {' '}
          Pronouns:
          {t('ms-teams-energy-block-chapters')}
          <span className="colourEnergyBreakdownChapters"> {pronoun}</span>
        </span> */}
      {/* <span style={{ marginLeft: 6 }}>
          {' '}
          {capitliseFirst(
            mapTeamsLocaleLanguageToLanguage(languageCode),
          )} - {pronoun}{' '}
        </span> */}
      {/* </h3> */}
      {fullProfile ? (
        <p
          className="colourEnergyBreakdownSubtitle"
          style={{ marginBottom: 15 }}
        >
          {' '}
          {t('ms-teams-energy-block-chapters')}
          <span className="colourEnergyBreakdownChapters">
            {' '}
            {chapters.join(', ')}
          </span>
        </p>
      ) : null}
      <EnergySplit
        theme="default" // no dark or contrast support for modal
        colourEnergyValues={colourEnergyValues}
        fullProfile={fullProfile}
        lng={lng}
      />
    </div>
  );
}

export default ColourEnergyBreakdown;
