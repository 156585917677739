import React, { ReactNode } from 'react';

interface OuterTemplateProps {
  content: ReactNode;
}

/**
 * The outer template container for the Insights flow allows
 * content to be changed out as the user progresses through the
 * flow as well as the information held throughout.
 */
function OuterTemplate({ content }: OuterTemplateProps) {
  return <div className="card p-4 d-flex flex-column justify-content-center align-items-center">{content}</div>;
}

export default OuterTemplate;
